import { TextBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    title: { type: 'identity' },
    timestamp: { type: 'identity' },
  },
  icon: <textblock></textblock>,
  naam: 'TITEL',
  titel: 'Titel',
  v: 0,
};
