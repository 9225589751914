import { GridBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: { breakpoints: { type: 'identity' } },
  icon: <gridblock></gridblock>,
  naam: 'GRID',
  titel: 'Rooster',
  v: 0,
};
