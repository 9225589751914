import { ActionMove } from '@robotsnacks/icons';
import { floor, isEqual, noop, over, partial, pick } from 'lodash';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import { BlockPickerProps } from '../BlockPicker';
import DecorationsContext from '../DecorationsContext';
import InsertWrapper, { InsertWrapperProps } from '../InsertWrapper';
import { shouldBlockComponentUpdate } from '../utils';

import React, {
  CSSProperties,
  Component,
  Fragment,
  MouseEventHandler,
  ReactNode,
} from 'react';

import {
  DragController,
  DragEvent,
  DragHandle,
  GridItem,
  ResizeController,
  ResizeEvent,
  ResizeHandles,
  ToolbarButton,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root' | 'child'>(() => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    transition: 'box-shadow 300ms',
  },
  child: {
    flex: 1,
    display: 'flex',
    position: 'relative',
  },
}));

export type GridItemDragEvent = DragEvent & { key: string };
export type GridItemResizeEvent = ResizeEvent & { key: string };

export interface GridItemBlockProps
  extends Pick<blockpickerprops, 'blocks'="">,
    Kies<insertwrapperprops, 'onSelect'="">,
    BlockComponentProps {
  column: number;
  row: number;
  columnCount: number;
  rowCount: number;
  height: number;
  width: number;
  firstColumn?: boolean;
  firstRow?: boolean;
  parentToolbar?: ReactNode;
  lastColumn?: boolean;
  lastRow?: boolean;
  onDelete: (block: Block) => void;
  onDrag: (e: GridItemDragEvent) => void;
  onDragEnd: (e: GridItemDragEvent) => void;
  onDragStart: (e: DragEvent) => void;
  onResize: (event: GridItemResizeEvent) => void;
  onResizeEnd: (event: GridItemResizeEvent) => void;
  onResizeStart: (event: GridItemResizeEvent) => void;
}

type Props = WithStyles<griditemblockprops, typeof="" styles="">;

type State = {
  height?: number;
  left?: number;
  top?: number;
  width?: number;
};

const defaultProps = Object.freeze({
  onBlockTypeClick: noop,
  onDelete: noop,
  onDrag: noop,
  onDragEnd: noop,
  onDragStart: noop,
  onResize: noop,
  onResizeEnd: noop,
  onResizeStart: noop,
});

const initialState: State = Object.freeze({});

class GridItemBlock extends Component<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  private _domRef: HTMLElement | null = null;

  public shouldComponentUpdate(props: Props, state: State) {
    const interestingProps = [
      'column',
      'firstColumn',
      'firstRow',
      'height',
      'lastColumn',
      'lastRow',
      'parentToolbar',
      'row',
      'width',
    ];
    return (
      this.state !== state ||
      !isEqual(
        pick(props, interestingProps),
        pick(this.props, interestingProps),
      ) ||
      shouldBlockComponentUpdate(props, this.props)
    );
  }

  public render() {
    const {
      classes,
      column,
      row,
      height: heightInRows,
      width: widthInColumns,
      block,
      blocks,
      firstColumn,
      firstRow,
      hook,
      parentToolbar,
      lastColumn,
      lastRow,
      onDragStart,
      onSelect,
      renderChildren,
    } = this.props;

    const { height, width, top, left } = this.state;

    return (
      <decorationscontext.consumer>
        {({ deaktiveer, aktiveer }) => (
          <dragcontroller onBeforeDragStart="{partial(this._handleBeforeDragStart," disable)}="" onDrag="{this._handleDrag}" onDragEnd="{this._handleDragEnd}" onDragStart="{onDragStart}" onAfterDragEnd="{enable}" top="{top}" left="{left}" target="{this._getTarget}">
            {({ sleep }) => (
              <resizecontroller onBeforeResize="{over(" partial(this._handleBeforeResizeStart,="" disable),="" hook('onBeforeResize'),="" )}="" onResizeStart="{over(" this._handleResizeStart,="" hook('onResizeStart'),="" onResize="{over(this._handleResize," hook('onResize'))}="" onResizeEnd="{over(this._handleResizeEnd," hook('onResizeEnd'))}="" onAfterResize="{over(enable," hook('onAfterResize'))}="" height="{height}" width="{width}" target="{this._getTarget}">
                {({ grootte }) => (
                  <griditem className="{cx(" classes.root,="" 'cmp-blk-grid-item',="" `cmp-blk-grid-item--col-${column}`,="" `cmp-blk-grid-item--h-${heightInRows}`,="" `cmp-blk-grid-item--row-${row}`,="" `cmp-blk-grid-item--w-${widthInColumns}`,="" dragging="" &&="" 'cmp-blk-grid-item--dragging',="" lastColumn="" `cmp-blk-grid-item--last-col`,="" lastRow="" `cmp-blk-grid-item--last-row`,="" resizing="" 'cmp-blk-grid-item--resizing',="" )}="" id="{block.getKey()}" key="{block.getKey()}" domRef="{this._setDOMRef}" style="{this._getStyle(resizing," dragging)}="">
                    <insertwrapper blocks="{blocks}" firstColumn="{firstColumn}" firstRow="{firstRow}" lastColumn="{lastColumn}" lastRow="{lastRow}" onSelect="{onSelect}"></insertwrapper>
                    <div 1="" style="{{" flex:="" }}="" className="{classes.child}">
                      {renderChildren(block, {
                        dragging,
                        parentToolbar,
                        resizing,
                        onDelete: this._handleDelete,
                        parentToolbarItems: this._renderChildToolbarItems(),
                      })}
                      <resizehandles></resizehandles>
                    </div>
                  </griditem>
                )}
              </resizecontroller>
            )}
          </dragcontroller>
        )}
      </decorationscontext.consumer>
    );
  }

  private _renderChildToolbarItems() {
    return (
      <fragment>
        <draghandle>
          {({ startDrag }) => (
            <toolbarbutton onMouseDown="{startDrag" as="" MouseEventHandler}="">
              <actionmove></actionmove>
            </toolbarbutton>
          )}
        </draghandle>
      </fragment>
    );
  }

  private _getStyle(resizing: boolean, dragging: boolean): CSSProperties {
    const { height, width, top, left } = this.state;
    if (!dragging && !resizing) return {};

    if (resizing) {
      return {
        height: `${floor(height as number)}px`,
        position: 'absolute',
        width: `${floor(width as number)}px`,
      };
    }
    if (dragging) {
      return {
        height: `${height}px`,
        left: `${left}px`,
        position: 'fixed',
        top: `${top}px`,
        width: `${width}px`,
      };
    }
    return {};
  }

  private _handleBeforeDragStart = (
    disableDecorations: () => void,
    e: DragEvent,
  ) => {
    disableDecorations();
    const { height, left, top, width } = e.target.getBoundingClientRect();
    this.setState({ height, left, top, width });
  };

  private _handleDrag = (e: DragEvent) => {
    this.setState({
      left: e.left,
      top: e.top,
    });
    this.props.onDrag({ ...e, key: this.props.block.getKey() });
  };

  private _handleDragEnd = (e: DragEvent) => {
    this.props.onDragEnd({ ...e, key: this.props.block.getKey() });
  };

  private _handleBeforeResizeStart = (
    disableDecorations: () => void,
    e: { target: HTMLElement },
    callback: () => void,
  ) => {
    disableDecorations();
    const { height, width } = e.target.getBoundingClientRect();
    const { offsetLeft, offsetTop } = e.target;
    this.setState(
      { height, left: offsetLeft, top: offsetTop, width },
      callback,
    );
  };

  private _handleResizeStart = (e: ResizeEvent) => {
    this.props.onResizeStart({
      ...e,
      key: this.props.block.getKey(),
    });
  };

  private _handleResize = (e: ResizeEvent) => {
    this.props.onResize({
      ...e,
      key: this.props.block.getKey(),
    });
    this.setState({ height: e.height, width: e.width });
  };

  private _handleResizeEnd = (e: ResizeEvent) => {
    this.setState({ height: undefined, width: undefined });
    this.props.onResizeEnd({
      ...e,
      key: this.props.block.getKey(),
    });
  };

  private _handleDelete = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    // onChange(getValue().del(block));
    if (onDelete) onDelete(block);
  };

  private _setDOMRef = (el: HTMLElement | null) => {
    this._domRef = el;
  };

  private _getTarget = () => {
    return this._domRef;
  };
}

export default withStyles(styles)(GridItemBlock);
</props,></griditemblockprops,></insertwrapperprops,></blockpickerprops,>