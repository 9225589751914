/* tslint:disable */

import React, { Component, ReactNode } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import ProductBlock from './ProductBlock';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import { SnipcartBlockAttributes } from './SnipcartBlockAttributes';
import SnipcartBlockToolbar from './SnipcartBlockToolbar';
import { EMPTY_ITEM } from './SnipcartOptionsFlyout';

export interface SnipcartBlockProps
  extends BlockComponentProps<snipcartblockattributes> {
  onDelete?: (block: Block<snipcartblockattributes>) => leemte;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
}

tipe rekwisiete = SnipcartBlockProps;

uitvoer standaardklas SnipcartBlock brei komponent uit<props> {
  public render() {
    const { block, parentToolbar, parentToolbarItems } = this.props;
    const item = block.getAttribute('item');
    return (
      <toolbarhover block="{block}">
        <div style="{{" position:="" 'relative'="" }}="">
          <toolbarwrapper>
            {parentToolbar}
            <snipcartblocktoolbar block="{block}" onDeleteClick="{this._handleDeleteClick}" parentItems="{parentToolbarItems}" attributes="{block.getAttributes()}" onChange="{this._handleAttributesChange}"></snipcartblocktoolbar>
          </toolbarwrapper>
          <productblock item="{item" ||="" EMPTY_ITEM}=""></productblock>
        </div>
      </toolbarhover>
    );
  }

  private _handleAttributesChange = (attributes: SnipcartBlockAttributes) => {
    const { block, getValue, onChange } = this.props;
    onChange(getValue().replace(block.setAttribute('item', attributes.item)));
  };

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    if (onDelete) onDelete(block);
  };
}
</props></snipcartblockattributes></snipcartblockattributes>