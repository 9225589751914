import { CardBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    description: { type: 'identity' },
    duration: { type: 'identity' },
    eventId: { type: 'identity' },
    start: { type: 'identity' },
    title: { type: 'identity' },
  },
  icon: <cardblock></cardblock>,
  naam: 'GEBEURTENIS',
  titel: 'Gebeurtenis',
  v: 0,
};
