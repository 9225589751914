import ImageClient from '@robotsnacks/image-client';
import { identity, merge, noop, pickBy } from 'lodash';
import React, { Component, ReactElement, ReactNode } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import CardBlockCard from './CardBlockCard';
import CardBlockWrapper from './CardBlockWrapper';
import ReadOnlyCardBlockBody from './ReadOnlyCardBlockBody';
import ReadOnlyCardBlockMedia from './ReadOnlyCardBlockMedia';
import ReadOnlyCardBlockSponsor from './ReadOnlyCardBlockSponsor';

import {
  CardBlockAttributes,
  CardBlockBreakpointAttributes,
  CardBlockBreakpointMediaAttributes,
  CardBlockMediaOrientation,
} from './CardBlockAttributes';

export interface ReadOnlyCardBlockProps
  extends BlockComponentProps<cardblockattributes> {
  defaults: CardBlockAttributes;
  imageClient?: ImageClient;
  onDelete: (block: Block<cardblockattributes>) => leemte;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
  liggaam?: ReactElement<any>;
  className?: string;
}

type Props = ReadOnlyCardBlockProps;

type State = {
  mediaHeight?: number;
  mediaWidth?: number;
  orientation?: CardBlockMediaOrientation;
  readonly?: boolean;
  expanded?: boolean;
};

const defaultProps = Object.freeze({
  onDelete: noop,
  className: '',
});

const initialState: State = Object.freeze({
  mediaHeight: undefined,
  mediaWidth: undefined,
  readonly: false,
  expanded: false,
});

export default class ReadOnlyCardBlock extends Component<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  // public shouldComponentUpdate(props: Props) {
  //   return shouldBlockComponentUpdate(props, this.props);
  // }

  componentDidMount() {
    // Check if we have media for the current breakpoint.
    const media = this._getCurrentMedia();
    // If not, force an update so we can measure the DOM.
    if (!media) this.forceUpdate();
  }

  render() {
    const { block, imageClient } = this.props;
    const media = this._getCurrentMedia();
    const sponsor = block.getAttribute('sponsor');
    // TODO: Right now, we're guessing on the orientation for SSR... we need
    // to add dynamic media-query-based classes or inline styles to support
    // proper SSR.
    return (
      <cardblockwrapper className="{['cs-card'," this.props.className].join('="" ').trim()}="" style="{{" cursor:="" block.getAttribute('expandedHtml')="" ?="" 'pointer'="" :="" undefined,="" }}="" id="{block.getKey()}" onClick="{()" ==""> {
          if (!block.getAttribute('to') && !block.getAttribute('href')) {
            this.setState({ expanded: !this.state.expanded });
          }
        }}
      >
        <cardblockcard orientation="{media.orientation}" to="{block.getAttribute('to')}" href="{block.getAttribute('href')}">
          {this._renderCardMedia()}
          <div 1="" style="{{" display:="" 'flex',="" flex:="" }}="">
            {this.props.children}
            <div style="{{" flex:="" 1,="" display:="" 'flex',="" flexDirection:="" 'column'="" }}="">
              <div 1="" style="{{" flex:="" }}="">{this._renderBody()}</div>
              {borg && borg.show && (
                <readonlycardblocksponsor block="{block}" imageClient="{imageClient}"></readonlycardblocksponsor>
              )}
            </div>
          </div>
        </cardblockcard>
      </cardblockwrapper>
    );
  }

  /* ---------------------------------------------------------------------------
   * Child Rendering Methods
   * ------------------------------------------------------------------------ */

  private _renderCardMedia() {
    const { imageClient } = this.props;
    const media = this._getCurrentCardBreakpoint().media;

    if (media && media.id) {
      return <readonlycardblockmedia media="{media}" imageClient="{imageClient}"></readonlycardblockmedia>;
    } else {
      return null;
    }
  }

  private _renderBody() {
    const { block, body } = this.props;
    if (body) {
      return body;
    } else {
      const html =
        this.state.expanded && block.getAttribute('expandedHtml')
          ? block.getAttribute('expandedHtml')
          : block.getAttribute('html');
      return <readonlycardblockbody key="{Math.random()}" value="{html}"></readonlycardblockbody>;
    }
  }

  /* ---------------------------------------------------------------------------
   * Attribute Getters
   * ------------------------------------------------------------------------ */

  private _getCurrentCardBreakpoint(): CardBlockBreakpointAttributes {
    const { currentBreakpoint } = this.props;
    const { mediaHeight, mediaWidth, orientation } = this.state;
    const breakpoints = this._getBreakpoints();
    const breakpoint = breakpoints[currentBreakpoint];
    if (mediaHeight || mediaWidth) {
      return merge(breakpoint, {
        media: pickBy(
          { height: mediaHeight, width: mediaWidth, orientation },
          identity,
        ),
      });
    }
    return breakpoint;
  }

  private _getCurrentMedia(): CardBlockBreakpointMediaAttributes {
    return this._getCurrentCardBreakpoint().media;
  }

  private _getBreakpoints() {
    const { block, defaults } = this.props;
    return block.getAttribute('breakpoints') || defaults.breakpoints;
  }
}
</props,></any></cardblockattributes></cardblockattributes>