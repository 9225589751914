import {
  Scroller,
  WithStyles,
  createStyles,
  withStyles,
  cx,
} from '@robotsnacks/ui';
import React, { Component } from 'react';
import BaseGridBlock, { BaseGridBlockProps } from '../BaseGridBlock';

const styles = createStyles<'root'>(() => ({
  root: {
    margin: '-20px 0',
    padding: '20px 0',
  },
}));

export type DeckBlockProps = WithStyles<basegridblockprops, typeof="" styles="">;

interface State {
  scrollLocked?: boolean;
}

const initialState: State = Object.freeze({});

class DeckBlock extends Component<deckblockprops, State=""> {
  state = initialState;

  private _scrollerRef: HTMLElement | null = null;
  private _lockTimer: any = null;

  componentDidMount() {
    const node = this._scrollerRef;
    if (node) {
      node.addEventListener('taffy:popup:opening', this._handlePopupOpening);
      node.addEventListener('taffy:popup:closing', this._handlePopupClosing);
    }
  }

  componentWillUnmount() {
    const node = this._scrollerRef;
    if (node) {
      node.removeEventListener('taffy:popup:opened', this._handlePopupOpening);
      node.removeEventListener('taffy:popup:closing', this._handlePopupClosing);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <scroller className="{cx(classes.root," 'cs-deck')}="" domRef="{this._setScrollerDOMRef}" locked="{this.state.scrollLocked}" suppressScrollY="">
        <basegridblock title="Pak" {...this.props}=""></basegridblock>
      </scroller>
    );
  }

  private _setScrollerDOMRef = (el: HTMLElement | null) => {
    this._scrollerRef = el;
  };

  private _setScrollLocked = (scrollLocked: boolean) => {
    this.setState({ scrollLocked });
  };

  private _handlePopupOpening = () => {
    this._lockScroller();
  };

  private _handlePopupClosing = () => {
    this._unlockScroller();
  };

  private _lockScroller = () => {
    if (this._lockTimer) clearTimeout(this._lockTimer as number);
    this._setScrollLocked(true);
  };

  private _unlockScroller = () => {
    if (this._lockTimer) clearTimeout(this._lockTimer as number);
    this._lockTimer = setTimeout(() => this._setScrollLocked(false), 500);
  };
}

export default withStyles(styles)(DeckBlock);
</deckblockprops,></basegridblockprops,>