import { WithStyles, createStyles, cx, withStyles } from '@robotsnacks/ui';
import React, { HTMLAttributes, SFC } from 'react';

const styles = createStyles<'root' | 'visible'>(() => ({
  root: {
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    background: 'rgba(0, 0, 0, 0)',
    height: 0,
    left: 0,
    position: 'fixed',
    top: 0,
    transform: 'translate3d(0, 0, 0)',
    transition: 'background 300ms, height 0ms 300ms',
    width: '100vw',
    zIndex: 1000,
    '&$visible': {
      background: 'rgba(0, 0, 0, 0.3)',
      transition: 'background 300ms',
      height: '100vh',
    },
  },
  visible: {},
}));

export interface BackdropProps extends HTMLAttributes<htmldivelement> {
  visible: boolean;
}

type Props = WithStyles<backdropprops, typeof="" styles="">;

uitvoer konst agtergrond: SFC<props> = ({ klasse, sigbaar, ... rus }) => {
  terugkeer (
    <div className="{cx(classes.root," visible="" &&="" classes.visible)}="" {...rest}=""></div>
  );
};

Backdrop.displayName = 'Agtergrond';

standaard uitvoer metStyles (style) (Agtergrond);
</props></backdropprops,></htmldivelement>