import { CardBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    breakpoints: { type: 'identity' },
    href: { type: 'identity' },
    html: { type: 'identity' },
    expandedHtml: { type: 'identity' },
    ops: { type: 'identity' },
    to: { type: 'identity' },
    sponsor: { type: 'identity' },
  },
  icon: <cardblock></cardblock>,
  naam: 'KAART',
  titel: 'Kaart',
  v: 0,
};
