import { List, Map, Record } from 'immutable';
import Delta, { InsertOp, Op, toStringPath } from '../delta';
import TreeNode from './TreeNode';

const DEFAULT_RECORD = {
  change: 0,
  data: Map<string, any="">(),
  redoStack: Stapel<tree>(),
  wortel: nuwe TreeNode (),
  undoStack: Stapel<tree>(),
};

type NodeOrKey = TreeNode | string;

const getKey = (nodeOrKey: NodeOrKey) =>
  typeof nodeOrKey === 'string' ? nodeOrKey : nodeOrKey.key;

/**
 *
 */
export default class Tree extends Record(DEFAULT_RECORD, 'Graph') {
  static create(delta?: Delta | Op[], data?: Map<string, any="">) {
    let graph = new Tree({ data });
    if (delta) {
      const ops =
        delta instanceof Delta ? delta : new Delta({ ops: List(delta) });
      graph = graph.applySilently(ops);
    }
    return graph.touch();
  }

  // TODO: add source here...... (user/api, etc.)
  public apply(delta: Delta): Tree {
    const updatedGraph = this.applySilently(delta);
    // return updatedGraph.set('undoStack', this.undoStack.push(this));
    // if (delta.containsUserOp()) {
    //   return updatedGraph.set('undoStack', this.undoStack.push(this));
    // }
    return updatedGraph.set('change', this.change + 1);
  }

  public touch(): this {
    return this.set('change', this.change + 1);
  }

  public applySilently(delta: Delta): Tree {
    return this.set('root', this.root.apply(this, delta));
  }

  // public undo(): Tree {
  //   const undoState = this.undoStack.peek();
  //   if (undoState) return undoState.set('redoStack', this.redoStack.push(this));
  //   return this;
  // }

  // public redo(): Tree {
  //   const redoState = this.redoStack.peek();
  //   if (redoState) return redoState.set('redoStack', this.undoStack.push(this));
  //   return this;
  // }

  public childrenOf(nodeOrKey: string | TreeNode): TreeNode[] {
    const node = this.find(nodeOrKey);
    if (!node) return [];
    return node.children.reduce(
      (reduction, n) => [...reduction, n, ...this.childrenOf(n)],
      [] as TreeNode[],
    );
  }

  public findParent(node: string | TreeNode): TreeNode | null {
    const path = this.findPath(node);
    if (path.length > 1) {
      return path[path.length - 2];
    }
    return null;
  }

  public find(nodeOrKey: NodeOrKey): TreeNode | null {
    const key = getKey(nodeOrKey);
    return this.root.find(key);
  }

  public findPath(nodeOrKey: NodeOrKey): Array<treenode> {
    const key = getKey(nodeOrKey);
    return this.root.findPath(key);
  }

  public toDelta(root: TreeNode = this.root): Delta {
    return toDelta(root);
  }
}

const toDelta = (root: TreeNode, delta: Delta = new Delta()): Delta => {
  const toInsertOp = (
    node: TreeNode,
    parent: TreeNode,
    index: number,
  ): InsertOp => ({
    attributes: node.attributes,
    insert: toStringPath([parent.key, index]),
    key: node.key,
    type: node.type,
  });

  delta = root.children.reduce(
    (d, node, i) => d.insert(toInsertOp(node, root, i)),
    delta,
  );

  root.children.forEach(node => (delta = toDelta(node, delta)));

  return delta;
};
</treenode></string,></tree></tree></string,>