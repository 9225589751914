/* tslint:disable */
import ImageClient from '@robotsnacks/image-client';
import { Image } from '@robotsnacks/ui';
import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { ImageBlockAttributes } from './ImageBlockAttributes';
import ImageBlockWrapper from './ImageBlockWrapper';
import { getImageHeight, getImageWidth } from './utils';

export interface ReadOnlyImageBlockProps
  extends BlockComponentProps<imageblockattributes> {
  client?: ImageClient;
  defaultPinterestDescription?: string;
}

class ReadOnlyImageBlock extends Component<readonlyimageblockprops> {
  public render() {
    const { block, client, defaultPinterestDescription } = this.props;
    const id = block.getAttribute('id');
    if (!id) return null;
    const link = block.getAttribute('link') || '';
    const hasLink = link.trim().length > 0;
    const Wrapper = hasLink ? 'a' : React.Fragment;
    const wrapperProps = hasLink ? { href: link } : {};
    return (
      <imageblockwrapper format="{block.getAttribute('_format')}">
        <wrapper {...wrapperProps}="">
          <image alt="{block.getAttribute('alt')}" className="{block.getAttribute('className')}" filename="{block.getAttribute('filename')" as="" string}="" fit="{block.getAttribute('fit')" ||="" ('outside'="" any)}="" imageId="{id}" title="{block.getAttribute('title')}" pinterestDescription="{" block.getAttribute('pinterestDescription')="" defaultPinterestDescription="" }="" client="{client}" width="{getImageWidth(block)}" height="{getImageHeight(block)}" style="{{" width:="" '100%'="" }}=""></image>
        </wrapper>
      </imageblockwrapper>
    );
  }
}

uitvoer standaard ReadOnlyImageBlock;
</readonlyimageblockprops></imageblockattributes>