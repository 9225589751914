import { DeckBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: { breakpoints: { type: 'identity' } },
  icon: <deckblock></deckblock>,
  naam: 'DECK',
  titel: 'Dek',
  v: 0,
};
