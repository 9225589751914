import React, { useEffect, useState } from 'react';
import { RouteProps } from 'react-router';
import { get } from 'lodash';
import gql from 'graphql-tag';
import qs from 'qs';
import { replace } from 'connected-react-router';
import styled from 'styled-components';
import { useDebouncedState } from '@mint/ui';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-apollo';

const SEARCH_QUERY = gql`
  query SearchPages($query: String) {
    pages(first: 50, query: $query) {
      edges {
        node {
          id
          title
          description
          path
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  font-family: sans-serif;
`;

const SearchHeading = styled.h1`
  text-align: center;
  margin-top: 3rem;
`;

const SearchInputWrapper = styled.div`
  margin: 0 0 1rem;
`;

const SearchInput = styled.input`
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  outline: none;
  border: 1px solid #bbb;
`;

const SearchResultWrapper = styled.a`
  display: block;
  padding: 1rem;
  text-decoration: none;
  color: black;
  margin-bottom: 0.5rem;

  &:hover {
    background: #fafafa;
  }
`;

const LoadingWrapper = styled.div`
  padding: 1rem;
  font-size: 1.25rem;
  text-align: center;
`;

const SearchResultTitle = styled.div`
  color: blue;
  font-size: 1.25rem;
  margin: 0 0 0.5rem;
  line-height: 1.5rem;
`;

const SearchResultDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
`;

const toPath = (path: string) =>
  typeof window !== 'undefined' && window.parent !== window
    ? path + '?viewport=true'
    : path;

const SearchResult = ({
  description,
  title,
  path,
}: {
  title: string;
  description: string;
  path: string;
}) => (
  <searchresultwrapper className="cs-search-result" href="{toPath(path)}">
    <searchresulttitle className="cs-search-result__title">
      {title}
    </searchresulttitle>
    <searchresultdescription className="cs-search-result__description">
      {beskrywing}
    </searchresultdescription>
  </searchresultwrapper>
);

const renderResults = (data: any, query: string, searchedOnce: boolean) => {
  const results = get(data, 'pages.edges', []);

  if (results.length > 0) {
    return results.map(({ node }) => (
      <searchresult key="{node.id}" title="{node.title}" description="{node.description}" path="{node.path}"></searchresult>
    ));
  } else if (searchedOnce && query.trim().length > 0) {
    return <loadingwrapper>Geen resultate nie.</loadingwrapper>;
  } else {
    return null;
  }
};

export default (props: RouteProps) => {
  const dispatch = useDispatch();

  const q = (
    qs.parse(get(props, 'location.search', ''), { ignoreQueryPrefix: true })
      .q || ''
  ).trim();

  const [searchedOnce, setSearchedOnce] = useState(q.length > 0);
  const [query, setQuery] = useState(q);
  const [debouncedQuery, setDebouncedQuery] = useDebouncedState(query, 500);

  const { data, loading } = useQuery(SEARCH_QUERY, {
    skip: query.length <= 0,
    variables: { query: debouncedQuery },
  });

  useEffect(() => {
    const q = qs.stringify({ q: debouncedQuery });
    dispatch(replace(`/search?${q}`));
  }, [debouncedQuery]);

  return (
    <wrapper className="cs-search-page">
      <searchheading>Soek</searchheading>
      <searchinputwrapper>
        <searchinput value="{query}" onChange="{e" ==""> {
            const value = e.target.value;
            setQuery(value);
            setDebouncedQuery(value);
            setSearchedOnce(true);
          }}
        />
      </searchinput></searchinputwrapper>
      {laai ? (
        <loadingwrapper>Laai tans...</loadingwrapper>
      ) : (
        renderResults (data, navraag, gesoekOnce)
      )}
    </wrapper>
  );
};
