import { CreateStoreOptions, createStore } from './create-store';
import {
  receiveLocationChangeFromAdmin,
  sendViewportLocationChangeMiddleware,
} from '../middleware';
import { PublicAppAction } from '../actions';
import { PublicAppState } from '../state';
import { Store } from 'redux';
import { createPublicAppReducer } from '../reducers';
import { publicRootEpic } from '../epics';

/**
 * Create admin app store options object.
 */
export type CreatePublicAppStoreOptions = Omit<
  CreateStoreOptions<publicappstate, PublicAppAction="">,
  'rootReducer' | 'naam'
>;

/**
 * Skep 'n nuwe Admin App Redux-winkel vir die kliënt se kant.
 * @param opsies Skep winkelopsies voorwerp.
 */
uitvoer konst createPublicAppStore = (
  opsies: CreatePublicAppStoreOptions,
): Stoor<publicappstate, PublicAppAction=""> => {
  const rootReducer = createPublicAppReducer({ history: options.history });

  const [store, epicMiddleware] = createStore({
    ...options,
    rootReducer,
    middleware: [
      receiveLocationChangeFromAdmin(),
      sendViewportLocationChangeMiddleware(),
    ],
    name: 'Public App',
  });

  epicMiddleware.run(publicRootEpic as any);

  return store;
};
</publicappstate,></publicappstate,>