import React, { SFC } from 'react';

import {
  Container,
  ContainerProps,
  Typography,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(() => ({
  root: {
    boxSizing: 'border-box',
    position: 'relative',
  },
}));

export type RichTextBlockWrapperProps = ContainerProps;

type Props = WithStyles<richtextblockwrapperprops, typeof="" styles="">;

const EventBlockWrapper: SFC<props> = ({
  Kinders
  Klasse
  klas Naam,
  ... Res
}) => (
  <container className="{cx(classes.root)}" type="prose" {...rest}="">
    {kinders}
  </container>
);

EventBlockWrapper.displayName = 'EventBlockWrapper';

standaard uitvoer metStyles (style) (EventBlockWrapper);
</props></richtextblockwrapperprops,>