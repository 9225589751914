import { Button, ButtonRow, Popup } from '@robotsnacks/ui';
import { DateTime, Duration } from 'luxon';
import React, { Component } from 'react';

import EventInput from './EventInput';
import EventListPopup from './EventListPopup';

type Props = {
  onCreateEvent: (event: any) => void;
  onSelectEvent: (event: any) => void;
  eventList?: Array<{
    description?: string;
    id: string;
    imageId?: string;
    title: string;
    start: DateTime;
    duration: Duration;
  }>;
};

interface State {
  popup?: 'create' | 'select';
}

class EventBlockPlaceholder extends Component<props, State=""> {
  public state: State = {};

  public render() {
    return (
      <div>
        <buttonrow style="{{" textAlign:="" 'center'="" }}="">
          {/* <popup in="{this.state.popup" =="=" 'create'}="" popup="{" <EventInput="" onCancel="{()"> this.setState({ popup: undefined })}
                onSave={event => {
                  this.setState({ popup: undefined });
                  this.props.onCreateEvent(event);
                }}
              />
            }
            style={{ zIndex: 100 }}
          >
            {({ popup }) => (
              <button onClick="{()" ==""> this.setState({ popup: 'create' })}>
                Create New Event {popup}
              </button>
            )}
          </popup> */}
          <popup in="{this.state.popup" =="=" 'select'}="" popup="{" <EventListPopup="" onSelectEvent="{this.props.onSelectEvent}" eventList="{this.props.eventList}"></popup>
            }
            style={{ zIndex: 100 }}
          >
            {({ popup }) => (
              <button onBlur="{()" ==""> this.setState({ popup: undefined })}
                onClick={() => this.setState({ popup: 'select' })}
              >
                Select Event {popup}
              </button>
            )}
          
        </buttonrow>
      </div>
    );
  }
}

uitvoer standaard EventBlockPlaceholder;
</props,>