import { ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import FeedOptionsFlyout from './FeedOptionsFlyout';

type Props = { onDeleteClick?: () => void } & Pick<
  BlockToolbarProps,
  'block' | 'parentItems'
> & {
    initiated?: boolean;
    cql?: string;
    onCqlChange?: (cql: string) => void;
  };

const TITLE = 'Feed';

const FeedBlockToolbar: SFC<props> = ({
  cql,
  onDeleteClick,
  onCqlChange,
  geïnisieer,
  ... Res
}) => (
  <blocktoolbar title="{TITLE}" {...rest}="">
    {geïnisieer && (      <>
        <feedoptionsflyout cql="{cql}" onCqlChange="{onCqlChange}"></feedoptionsflyout>
        <toolbarseparator></toolbarseparator>
      </>
    )}
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TITLE}"></deleteblockflyout>
  </blocktoolbar>
);

FeedBlockToolbar.displayName = 'RichTextBlockToolbar';

standaard FeedBlockToolbar uitvoer;
</props>