import {
  Container,
  createStyles,
  cx,
  Typography,
  WithStyles,
  withStyles,
} from '@robotsnacks/ui';
import { get, reduce } from 'lodash';
import React, { Component } from 'react';
import CardBlockCard from '../CardBlock/CardBlockCard';
import EventDateTime from '../EventCardBlock/EventDateTime';
import normalizeItems from './normalize-items';

const styles = createStyles<'root'>(theme => ({
  root: {
    background: theme.color('background'),
    padding: 6,
    position: 'relative',
  },
  card: {
    margin: '1rem',
  },
  event: {
    display: 'flex',
    h1: {
      marginBottom: 0,
    },
    '& $typography': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  typography: {
    flex: 1,
    padding: '0.7rem',
    '& > :first-child': {
      marginTop: '0',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  dateWrapper: {
    alignItems: 'center',
    borderRight: '1px solid rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    // marginRight: '1rem',
    padding: '0.7rem',
    width: '3.5rem',
  },
}));

export interface FeedListProps {
  feed: any;
  block: any;
}

type Props = WithStyles<feedlistprops, typeof="" styles="">;

klas Voerlys brei komponent uit<props> {
  public render() {
    const { classes, feed } = this.props;

    const items = normalizeItems(feed).map((item: any) => {
      const type = item.startsAt ? 'event' : 'page';
      const description = item.startsAt
        ? null
        : (item.description || '').split(/\n+/g);
      return (
        <cardblockcard className="{cx(classes.card," 'cs-feed-list',="" classes[type])}="" key="{item.id}" to="{item.to}">
          <div className="{classes.wrapper}">
            {item.startsAt && (
              <div className="{classes.dateWrapper}">
                <eventdatetime block="{item}"></eventdatetime>
              </div>
            )}
            <typography className="{classes.typography}">
              <h1>{item.titel || item.name}</h1>
              {description &&
                description.map((line: string, i: number) => (
                  <p key="{i}">{line}</p>
                ))}
            </typography>
          </div>
        </cardblockcard>
      );
    });

Terugkeer <container>{items}</container>;
  }
}

standaard uitvoer metStyles (style) (FeedList);
</props></feedlistprops,>