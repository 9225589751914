import React, { CSSProperties, Component, ReactNode } from 'react';
import BlockPicker, { BlockPickerProps } from '../BlockPicker';

import {
  Popup,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root' | 'wrapper' | 'title' | 'message'>(
  theme => ({
    root: {
      ...theme.font('control'),
      outline: 0,
      position: 'relative',
    },
    wrapper: {
      alignItems: 'center',
      background: theme.color('grey', '50'),
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      lineHeight: 2,
      padding: '2em',
      position: 'relative',
      textAlign: 'center',
      transform: 'color 240ms',
      transition: 'background-color 150ms',
      width: '100%',
      '$root:focus &, &:hover': {
        background: theme.color('grey', '100'),
      },
    },
    title: {
      fontSize: 30,
    },
    message: {
      fontSize: 16,
    },
  }),
);

export interface PlaceholderProps extends BlockPickerProps {
  children?: never;
  className?: string;
  id?: string;
  title?: ReactNode;
  message?: ReactNode;
  onClick?: () => void;
  disableBlockPicker?: boolean;
  style?: CSSProperties;
}

type Props = WithStyles<placeholderprops, typeof="" styles="">;

type State = {
  focused: boolean;
};

const defaultProps = Object.freeze({
  title: 'This block is empty.',
  message: 'Click to add your first block.',
});

const initialState = Object.freeze({
  focused: false,
});

const handleFocus = Symbol();
const handleBlur = Symbol();

class Placeholder extends Component<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  render() {
    const {
      blocks,
      classes,
      className,
      disableBlockPicker,
      id,
      message,
      onClick,
      onSelect,
      title,
      style,
    } = this.props;
    return (
      <popup in="{this.state.focused}" popup="{<BlockPicker" blocks="{blocks}" onSelect="{onSelect}"></popup>}
        style={{ zIndex: 100 }}
      >
        {({ popup }) => (
          <div id="{id}" onFocus="{!disableBlockPicker" ?="" this[handleFocus]="" :="" undefined}="" onBlur="{!disableBlockPicker" this[handleBlur]="" tabIndex="{0}" className="{cx(classes.root," className)}="" onClick="{onClick}" style="{style}">
            <div className="{classes.wrapper}">
              <div className="{classes.title}">{title}</div>
              <div className="{classes.message}">{message}</div>
            </div>
            {pop-up}
          </div>
        )}
      
    );
  }

  [handleFocus] = () => {
    this.setState({ focused: true });
  };

  [handleBlur] = () => {
    this.setState({ focused: false });
  };
}

export default withStyles(styles)(Placeholder);
</props,></placeholderprops,>