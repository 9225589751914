import { includes } from 'lodash';
import React, { CSSProperties, HTMLAttributes, ReactNode, SFC } from 'react';
import Arrow, { ArrowDirection } from '../Arrow';
import Paper, { PaperProps } from '../Paper';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    border: `1px solid ${theme.color('grey', '200')}`,
    borderRadius: 2,
    position: 'relative',
  },
}));

export type ArrowBoxProps = {
  arrowLeft?: number;
  arrowTop?: number;
  children?: ReactNode;
  color?: string;
  direction?: ArrowDirection;
  domRef?: (el: HTMLDivElement | null) => void;
  size?: number;
} & HTMLAttributes<htmldivelement> &
  Kies<paperprops, 'elevation'="">;

tipe rekwisiete = Metstyle<arrowboxprops, typeof="" styles="">;

const berekenArrowStyle = (
  rekwisiete: Kies<props, 'arrowLeft'="" |="" 'arrowTop'="" 'direction'="">,
): CSSProperties => ({
  left:
    includes(['up', 'down'], props.direction) && props.arrowLeft
      ? `${props.arrowLeft}px`
      : undefined,
  top:
    includes(['left', 'right'], props.direction) && props.arrowTop
      ? `${props.arrowTop}px`
      : undefined,
});

const ArrowBox: SFC<props> = ({
  pylLeft,
  pylTop,
  Kinders
  Klasse
  klas Naam,
  Kleur
  Rigting
  hoogte = 9,
  Grootte
  Tema
  ... rekwisiete
}) => (
  <paper className="{cx(classes.root," className)}="" elevation="{elevation}" {...props}="">
    {kinders}
    <arrow color="{color}" size="{size}" direction="{direction}" style="{calculateArrowStyle({" arrowLeft,="" arrowTop,="" })}=""></arrow>
  </paper>
);

standaard uitvoer metStyles (style) (ArrowBox);
</props></props,></arrowboxprops,></paperprops,></htmldivelement>