import { createStyles, withStyles } from '@robotsnacks/ui';
import { DateTime, Duration } from 'luxon';
import React from 'react';

const style = createStyles<'root' | 'date' | 'day' | 'month' | 'time'>(
  theme => ({
    root: {
      display: 'flex',
    },
    date: {
      display: 'inline-flex',
      flexDirection: 'column',
      ...theme.font('p'),
      lineHeight: 1.5,
    },
    day: {
      textAlign: 'center',
      fontSize: 26,
      lineHeight: 1.35,
    },
    month: {
      textAlign: 'center',
      fontSize: 13,
      textTransform: 'uppercase',
      opacity: 0.7,
    },
    time: {
      textAlign: 'center',
      fontSize: 10,
      fontWeight: 'bold',
      opacity: 0.7,
      whiteSpace: 'nowrap',
    },
  }),
);

function EventDateTime(props: any) {
  const { block, classes } = props;
  const start = DateTime.fromISO(
    block.getAttribute ? block.getAttribute('start') : block.start,
  );
  return (
    <div className="{classes.date}">
      <span className="{classes.month}">{start.monthShort}</span>
      <span className="{classes.day}">{start.day}</span>
      <span className="{classes.month}">{start.toFormat('ccc')}</span>
      <span className="{classes.time}">
        {start.toLocaleString(DateTime.TIME_SIMPLE)}
      </span>
    </div>
  );
}

standaard uitvoer metStyles (styl) (EventDateTime);
