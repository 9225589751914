import {
  includes,
  isEqual,
  keys,
  min,
  noop,
  partial,
  reduce,
  values,
} from 'lodash';
import React, { Component } from 'react';
import { BlockPickerProps } from '../BlockPicker';
import InsertLine from '../InsertLine';

import {
  EventListener,
  WithStyles,
  createStyles,
  cx,
  getClientCoordinates,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<
  'root' | 'side' | 'north' | 'south' | 'east' | 'west'
>(() => ({
  root: {
    height: '100%',
    left: 0,
    position: 'absolute',
    width: '100%',
  },
  side: {
    position: 'absolute',
    height: 0,
    width: 0,
  },
  north: {
    width: '100%',
    top: 0,
  },
  south: {
    width: '100%',
    bottom: 0,
  },
  east: {
    height: '100%',
    right: 0,
  },
  west: {
    height: '100%',
    left: 0,
  },
}));

const MIN_VISIBLE_DISTANCE = 30;

export type InsertWrapperProps = {
  active?: boolean;
  className?: string;
  decorationKey?: string;
  first?: boolean;
  firstColumn?: boolean;
  firstRow?: boolean;
  last?: boolean;
  lastColumn?: boolean;
  lastRow?: boolean;
  nearest?: boolean;
  onActive?: () => void;
  onInactive?: () => void;
  onSelect: (side: 'north' | 'south' | 'east' | 'west', name: string) => void;
} & Pick<blockpickerprops, 'blocks'="">;

tipe rekwisiete = Metstyle<insertwrapperprops, typeof="" styles="">;

type State = {
  nearest?: string | null;
  visibleSides?: string[];
};

const defaultProps = Object.freeze({
  onSelect: noop,
});

const initialState: State = Object.freeze({});

const calculateNearestSide = (distances: { [side: string]: number }) => {
  const ks = keys(distances);
  const vs = values(distances);
  const idx = vs.indexOf(min(vs) || Infinity);
  if (idx >= 0) return ks[idx];
  return null;
};

class InsertWrapper extends Component<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  private _lineRefs: { [side: string]: HTMLElement | null } = {};

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      this.state.nearest !== nextState.nearest ||
      !isEqual(this.state.visibleSides, nextState.visibleSides) ||
      this.props !== nextProps
    );
  }

  render() {
    const {
      blocks,
      classes,
      firstColumn,
      firstRow,
      lastColumn,
      lastRow,
      onSelect,
    } = this.props;

    const { nearest, visibleSides } = this.state;

    // const distances = this._calculateLineDistances();
    // const visibleSides = this._calculateVisibleSides(distances);
    // const nearest = calculateNearestSide(distances);

    return (
      <eventlistener onMouseMove="{this._handleMouseMove}">
        <div className="{classes.root}">
          {['north', 'south', 'east', 'west'].map(side => {
            const first =
              (side === 'north' && firstRow) ||
              (side === 'west' && firstColumn);
            const last =
              (side === 'south' && lastRow) || (side === 'east' && lastColumn);
            return (
              <insertline blocks="{blocks}" className="{cx(classes.side," (classes="" as="" any)[side])}="" first="{first}" key="{side}" last="{last}" nearest="{nearest" =="=" side}="" onSelect="{partial(onSelect," side="" any)}="" vertical="{side" 'east'="" ||="" 'west'}="" in="{includes(visibleSides," side)}="" domRef="{partial(this._setLineRef,"></insertline>
            );
          })}
          {this.props.children}
        </div>
      </eventlistener>
    );
  }

  private _handleMouseMove = (e: MouseEvent) => {
    const { clientX, clientY } = getClientCoordinates(e)!;
    const distances = this._calculateLineDistances(clientX, clientY);
    const visibleSides = this._calculateVisibleSides(distances);
    const nearest = calculateNearestSide(distances);
    this.setState({ nearest, visibleSides });
  };

  private _calculateLineDistances(
    clientX: number,
    clientY: number,
  ): { [side: string]: number } {
    if (typeof clientX !== 'number' || typeof clientY !== 'number') return {};
    return reduce(
      this._lineRefs,
      (acc, el, side) => {
        if (!el) {
          acc[side] = Infinity;
          return acc;
        }
        if (!el) {
          acc[side] = Infinity;
          return acc;
        }

        let val = Infinity;
        const { top, right, bottom, left } = el.getBoundingClientRect();

        // Build a bounding box using our min distance.
        const box = {
          bottom: bottom + MIN_VISIBLE_DISTANCE,
          left: left - MIN_VISIBLE_DISTANCE,
          right: right + MIN_VISIBLE_DISTANCE,
          top: top - MIN_VISIBLE_DISTANCE,
        };

        // Check if we're within range on x and y axes.
        const xInRange = clientX >= box.left && clientX <= box.right;
        const yInRange = clientY <= box.bottom && clientY >= box.top;
        if (xInRange && yInRange) {
          const x = Math.min(
            Math.abs(clientX - left),
            Math.abs(clientX - right),
          );
          const y = Math.min(
            Math.abs(clientY - top),
            Math.abs(clientY - bottom),
          );
          // If so, use the minimum distance measurement.
          val = Math.min(x, y);
        }

        acc[side] = val;
        return acc;
      },
      {} as { [side: string]: number },
    );
  }

  private _calculateVisibleSides(distances: {
    [key: string]: number;
  }): string[] {
    return reduce(
      distances,
      (acc, distance, side) => {
        if (distance < MIN_VISIBLE_DISTANCE) {
          acc.push(side);
        }
        return acc;
      },
      [] as string[],
    ).sort();
  }

  private _setLineRef = (side: string, line: HTMLElement | null) => {
    this._lineRefs[side] = line;
  };
}

export default withStyles(styles)(InsertWrapper);
</props,></insertwrapperprops,></blockpickerprops,>